import React from 'react'
import { graphql  } from 'gatsby'
import Helmet from 'react-helmet'
import { MDXWrapper } from '../components/mdx-provider'
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import posed from 'react-pose'

import { LocaleContext } from "../components/layout"
import Link from '../components/localizedLink'
import SEO from '../components/seo'
import List from '../components/list'
import Image from 'gatsby-image'
import ButtonAction from '../components/button-action'
import StringToSlug from '../utils/string-to-slug'

import socialImage from '../images/logocuadrado2redes.png'

import useTranslations from "../components/useTranslations"
import stringToSlug from '../utils/string-to-slug'

const Gallery = posed.div({
  enter: { staggerChildren: 100, delayChildren: 400 },
  exit: { staggerChildren: 100, staggerDirection: -1 }
})

const GalleryItem = posed.div({
  enter: { scale: 1, opacity: 1, delay: 0 },
  exit: { scale: 0.95, opacity: 0, delay: 0 }
})

const Page = ({ data: { page: postNode, images }, location }) => {
  console.log(postNode);
  const { locale } = React.useContext(LocaleContext)
  const page = postNode.frontmatter
  const { menu } = useTranslations()
  const isColecciones = /colecciones/i.test(postNode.fileAbsolutePath)
  const isMexico = /mexico/i.test(postNode.fileAbsolutePath)
  const isColeccion = /coleccion/i.test(page.type)
  const isVestido = /vestido/i.test(page.type)
  const isContacto = /contacto/.test(postNode.fileAbsolutePath)
  const isPuntos = /puntos/i.test(postNode.fileAbsolutePath)
  const isGracias = /gracias/i.test(postNode.fileAbsolutePath)
  
  return (
    <>
      <SEO 
        title={page.title}
        lang={locale}
        postImage={postNode.frontmatter.banner ? postNode.frontmatter.banner.publicURL : null}
      />
      <Helmet>
        {/* Global site tag (gtag.js) - Google Ads: 837151511 */}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-P4P437C');`}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SPF8DG5Z57"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-SPF8DG5Z57');
 
          window.addEventListener('load',function(){
          if (window.location.pathname == "/gracias") {
             console.log('test event conversion gracias');
             gtag('event', 'conversion', {'send_to': 'AW-837151511/rMMYCKG6jPoCEJfWl48D'});
           }
           
          document.querySelector('[href*="tel:"]').addEventListener('click',function() {
                console.log('test event conversion tel');
                gtag('event', 'conversion', {'send_to': 'AW-837151511/stIvCITTjPoCEJfWl48D'});
              });
          });

          `}
        </script>
      </Helmet>
      <Helmet
          bodyAttributes={{
              class: page.type === 'vestido' ? StringToSlug(page.collection) : page.type === 'coleccion' ? StringToSlug(page.title) : null
          }}
      />
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P4P437C" height="0" width="0" style={{ display:'none', visibility:'hidden' }}></iframe></noscript>
      <main className={`main ${isContacto || isPuntos ? 'main--page' : 'main--archive'}`}>
        <div className="main__container">
          <article className="article main__wrapper main__wrapper--head">
            <div className={`main__wrapper-inner ${isPuntos ? `main__wrapper-inner_puntos` : null}`}>
              <header>
                {isColeccion &&
                  <Link to={`/${menu.items[0].slug}`} className="main__navigation">{ menu.items[0].name } &nbsp;←</Link>
                }
                {isMexico && !isColeccion &&
                  <Link to={`${locale === 'es' ? '/colecciones/mexico' : '/collections/mexico'}`} className="main__navigation">México &nbsp;←</Link>
                }
                {isVestido &&
                <Link 
                  to={`/${menu.items[0].slug}/${stringToSlug(page.collection)}`}
                  className="main__navigation"
                >
                  {page.collection} &nbsp;←
                </Link>
                }   
                <h1 className="entry-title">{page.title}</h1>
              </header>
              <MDXWrapper>
                <MDXRenderer>
                  {postNode.body}
                </MDXRenderer>
              </MDXWrapper>
              <div className="entry-footer">
                {isVestido &&
                <ButtonAction/>
                } 
                { !isContacto && !isGracias &&
                  <div className="share" style={{ marginTop: '2rem' }}>
                    <span className="share__label">{locale === 'es' ? 'compartir ' : 'share '} &#8212;&#8212;&nbsp;</span>
                    {/* Ojo, no funciona */}
                    <a 
                      className="share__item" 
                      href={`https://twitter.com/intent/tweet?text=${page.title}&amp;url=${location.href}`}  
                      rel="noopener noreferrer" 
                      target="_blank">
                        twitter
                    </a>
                    <a 
                      className="share__item" 
                      href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}  
                      rel="noopener noreferrer" 
                      target="_blank">
                        facebook
                    </a>
                    <a 
                      className="share__item" 
                      href={`http://pinterest.com/pin/create/button/?url=${location.href}&amp;media=${location.origin}${page.type === 'vestido' || page.type === 'coleccion' ? page.banner.publicURL : socialImage }&amp;description=${page.title} | Atelier Paola Carolina`}  
                      rel="noopener noreferrer" 
                      target="_blank">
                        pinterest
                    </a>
                  </div>
                }
              </div>
            </div>
          </article>
          {postNode.exports.list && 
            <div className="main__wrapper">
              <List 
                type={postNode.exports.list.type}
                collection={postNode.exports.list.collection}
                tag={postNode.exports.list.tag}
                />
            </div>
          }
          {/* Mostra automaticament les imatges dins de la carpeta /images tret de la carpeta colecciones */}
          {!isColecciones &&
            <div className="main__wrapper">
              <Gallery className="gallery gallery-columns-1" style={{ display: 'block' }}>
                {images.nodes.map(image => (
                    <GalleryItem key={image.childImageSharp.fluid.src} className="gallery-item">
                      <Image
                        alt={image.name}
                        fluid={image.childImageSharp.fluid}
                        style={{ marginBottom: '3rem' }}
                      />
                    </GalleryItem>
                ))}
              </Gallery>
            </div>
          }
        </div>
      </main>
    </>
  )
}

export default Page

export const query = graphql`
  query Page($locale: String!, $title: String!, $imagesPath: String!)  {
    images: allFile(
      filter: {
        absolutePath: { regex: $imagesPath }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 950, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
      page: mdx(
        frontmatter: { title: { eq: $title } }
        fields: { locale: { eq: $locale } }
      ) {
        parent {
          ... on File {
            mtime
            birthtime
          }
        }
        fileAbsolutePath
        frontmatter {
          title
          type
          slug
          collection
          banner {
            publicURL
            childImageSharp {
              sizes(maxWidth: 900) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        body
        exports {
          list {
            type
            collection
            tag
          }
        }
      }
  }
`
